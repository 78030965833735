import * as React from "react"

import { DesignComponent } from "@supernovaio/sdk"

export function DMComponentMiniature({
  component,
  height,
  width,
}: {
  component: DesignComponent
  height: number
  width: number
}) {
  return (
    <div
      className="flex shrink-0 items-center justify-center p-2 rounded-small"
      style={{ backgroundColor: "#F5F5F5", height, width }}
    >
      <img
        alt={component.name}
        className="max-h-full max-w-full"
        src={component.thumbnailUrl || undefined}
      />
    </div>
  )
}
