"use client"

import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import * as CollapsiblePrimitive from "@radix-ui/react-collapsible"

const { CollapsibleContent } = CollapsiblePrimitive

const DMCollapsibleContent = React.forwardRef<
  React.ElementRef<typeof CollapsibleContent>,
  React.ComponentPropsWithoutRef<typeof CollapsibleContent>
>(({ className, children, ...props }, ref) => (
  <CollapsibleContent
    ref={ref}
    className={cn(
      "data-overflow-hidden flex flex-col gap-8 pt-8 transition-all",
      className
    )}
    {...props}
  >
    {children}
  </CollapsibleContent>
))

DMCollapsibleContent.displayName = CollapsibleContent.displayName

export { DMCollapsibleContent }
