"use client"

import React, { forwardRef } from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { HTMLMotionProps, motion } from "framer-motion"

export type ThProps = Omit<HTMLMotionProps<"th">, "ref"> & {
  allowTransition?: boolean
}

const DMDataTableTh = forwardRef<HTMLTableCellElement, ThProps>(
  ({ className, allowTransition, ...props }, ref) => {
    return (
      <motion.th
        ref={ref}
        className={cn("z-10 p-[0]", className)}
        layout="position"
        transition={allowTransition ? undefined : { duration: 0 }}
        {...props}
      />
    )
  }
)

DMDataTableTh.displayName = "DMDataTableTh"

export default DMDataTableTh
