"use client"

import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { HTMLMotionProps, motion } from "framer-motion"

export type TdProps = HTMLMotionProps<"td"> & { allowTransition?: boolean }

export default function DMDataTableTd({
  children,
  className,
  stickToLeft,
  stickToRight,
  asDiv,
  asTh,
  allowTransition,
  ...props
}: TdProps & {
  depth?: number
  stickToLeft?: boolean
  stickToRight?: boolean
  asDiv?: boolean
  asTh?: boolean
}) {
  const CellTag = motion[asDiv ? "div" : asTh ? "th" : "td"]

  return (
    <CellTag
      transition={allowTransition ? undefined : { duration: 0 }}
      layout
      {...props}
      className={cn([
        "text-body h-table-cell",
        "border-neutral-faded border-b border-r",
        "group-[.selected]/row:bg-primary-faded transition-colors",
        stickToLeft &&
          "bg-white group-hover/clickable-row:bg-blur-neutral-faded sticky left-0 z-10 group-[.scrolled]/table:border-r",
        stickToRight &&
          "bg-white group-hover/clickable-row:bg-blur-neutral-faded sticky right-0 z-10 group-[.overflowing]/table:border-l",
        className,
      ])}
    >
      <>
        {children}
        {stickToLeft && (
          <div
            className="absolute -bottom-1 -right-1 z-20 w-[12px] opacity-0 group-[.scrolled]/table:opacity-100"
            style={{
              transform: "translateX(100%)",
              height: "calc(100% + 1px)",
              background:
                "linear-gradient(to right, rgba(20, 23, 31, 0.03) 0%, rgba(20, 23, 31, 0.0) 100%)",
            }}
          />
        )}
        {stickToRight && (
          <div
            className="invisible absolute -bottom-1 -left-1 z-20 w-[12px] opacity-0 transition-opacity group-[.overflowing]/table:visible group-[.overflowing]/table:opacity-100"
            style={{
              transform: "translateX(-100%)",
              height: "calc(100% + 1px)",
              background:
                "linear-gradient(to left, rgba(20, 23, 31, 0.03) 0%, rgba(20, 23, 31, 0.0) 100%)",
            }}
          />
        )}
      </>
    </CellTag>
  )
}
