import * as React from "react"
import { SVGProps } from "react"

export const IconUrl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M6.667 9.333a2.333 2.333 0 0 0 3.333 0l2.667-2.666a2.357 2.357 0 0 0-3.334-3.334L9 3.667m.333 3a2.333 2.333 0 0 0-3.333 0L3.333 9.333a2.357 2.357 0 1 0 3.334 3.334L7 12.333"
    />
  </svg>
)
