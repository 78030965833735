import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconDragHandle } from "@supernovaio/icons"

import DMDataTableTd, { TdProps } from "../DMDataTableTd"

export type TitleCellProps = {
  cellValue: string
  depth?: number
  draggable?: boolean
  dragHandleRef?: React.LegacyRef<HTMLSpanElement>
  suffix?: React.ReactNode
  endSlot?: React.ReactNode
  isGroup?: boolean
} & Pick<TdProps, "className" | "style">

export default function DMDataTableTitleCell({
  cellValue,
  depth = 0,
  endSlot,
  suffix,
  className,
  draggable,
  dragHandleRef,
  style,
  isGroup,
  ...props
}: TitleCellProps) {
  return (
    <DMDataTableTd
      stickToLeft
      className={cn(
        "text-body text-neutral whitespace-nowrap font-semibold",
        className
      )}
      style={{ paddingLeft: 28 + 16 * depth, ...(style || {}) }}
    >
      <div className="flex w-full items-center justify-between">
        <span
          ref={dragHandleRef}
          className={cn(
            "invisible absolute left-8",
            draggable && "cursor-grab group-hover/clickable-row:visible"
          )}
        >
          <IconDragHandle />
        </span>
        <div className="flex w-full items-center justify-between gap-4">
          {/* {multiselect ? (
            <div className="flex items-center gap-4 overflow-hidden whitespace-nowrap">
              <Checkbox {...(props as OriginalCheckBoxProps)}>
                <p>{cellValue}</p>
              </Checkbox>
              {suffix}
            </div>
          ) : ( */}
          <div className="flex items-center gap-4 overflow-hidden">
            <p>{cellValue}</p>
            {suffix}
          </div>
          {/* )} */}
        </div>
        {endSlot && (
          <div
            className={cn(
              "px-table-cell absolute right-0 top-0 flex h-full items-center",
              "from-85% to-100% group-hover/row:bg-gradient-to-l",
              "from-[var(--rs-color-background-blur-neutral-faded)] to-[rgba(var(--rs-color-rgb-background-blur-neutral-faded),0)]",
              isGroup &&
                "from-[var(--rs-color-background-blur-neutral-highlighted)] to-[rgba(var(--rs-color-rgb-background-blur-neutral-highlighted),0)]",
              "opacity-0 transition-opacity group-hover/row:opacity-100",
              "group-[.selected]/row:from-[var(--rs-color-background-primary-faded)] group-[.selected]/row:to-[rgba(var(--rs-color-rgb-background-primary-faded),0)]",
              "has-dropdown-open:opacity-100"
            )}
          >
            {endSlot}
          </div>
        )}
      </div>
    </DMDataTableTd>
  )
}
