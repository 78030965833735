"use client"

import * as React from "react"

import * as CollapsiblePrimitive from "@radix-ui/react-collapsible"

const { CollapsibleTrigger } = CollapsiblePrimitive

const DMCollapsibleTrigger = React.forwardRef<
  React.ElementRef<typeof CollapsibleTrigger>,
  React.ComponentPropsWithoutRef<typeof CollapsibleTrigger>
>(({ className, children, ...props }, ref) => (
  <CollapsibleTrigger ref={ref} className={className} {...props}>
    {children}
  </CollapsibleTrigger>
))

DMCollapsibleTrigger.displayName = CollapsibleTrigger.displayName

export { DMCollapsibleTrigger }
