"use client"

import * as React from "react"

import { DMDivider } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconCaret } from "@supernovaio/icons"

import * as CollapsiblePrimitive from "@radix-ui/react-collapsible"

import { DMIcon } from "../DMIcon"

import { DMCollapsibleContent } from "./DMCollapsibleContent"
import { DMCollapsibleTrigger } from "./DMCollapsibleTrigger"

const { Collapsible } = CollapsiblePrimitive

export type DMCollapsibleProps = Pick<
  React.ComponentPropsWithoutRef<typeof Collapsible>,
  "className" | "title" | "open" | "children" | "onOpenChange" | "defaultOpen"
> & {
  showDivider?: boolean
  contentClassName?: string
}

function DMCollapsible(props: DMCollapsibleProps) {
  const {
    title,
    open,
    children,
    showDivider = false,
    onOpenChange,
    className,
    contentClassName,
    defaultOpen,
  } = props

  return (
    <Collapsible
      className={cn("group", className)}
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
    >
      <div className="flex grow flex-row items-center">
        <DMCollapsibleTrigger asChild>
          <button
            className="text-body-small text-neutral-faded hover:text-neutral flex flex-row items-center rounded outline-0 ring-offset-1 transition focus-visible:ring-2"
            type="button"
          >
            {title}
            <DMIcon
              className="rotate-90 transition-transform group-data-[state=closed]:rotate-0"
              svg={IconCaret}
            />
          </button>
        </DMCollapsibleTrigger>
        {showDivider && <DMDivider className="ml-8 grow" />}
      </div>
      <DMCollapsibleContent className={contentClassName}>
        {children}
      </DMCollapsibleContent>
    </Collapsible>
  )
}

DMCollapsible.displayName = "Collapsible"

export { DMCollapsible }
