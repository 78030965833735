import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import DMDataTableTd, { TdProps } from "../DMDataTableTd"

export type TextCellProps = Omit<TdProps, "value"> & {
  value: string | number
}

export default function DMDataTableTextCell({
  value,
  className,
  ...props
}: TextCellProps) {
  // number 0 values are accepted as falsy, we need to check if it exists instead of just `value &&`
  const valueExists = value !== undefined && value !== null

  return (
    <DMDataTableTd
      {...props}
      className={cn(
        valueExists ? "text-body" : "text-neutral-placeholder",
        className
      )}
    >
      <div
        className={cn(
          "pl-table-cell w-full overflow-x-hidden whitespace-nowrap",
          props.align === "center" && "pr-table-cell"
        )}
      >
        {valueExists ? value : "Empty"}
      </div>
    </DMDataTableTd>
  )
}
