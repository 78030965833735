import * as React from "react"
import { SVGProps } from "react"

export const IconFigma = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M10 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4m0 0a2 2 0 0 0 0-4H6a2 2 0 1 0 0 4m4 0H6m0 0a2 2 0 1 0 0 4m0 0h2m-2 0a2 2 0 1 0 2 2V2"
    />
  </svg>
)
