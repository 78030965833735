import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

type Props = React.DetailedHTMLProps<
  React.TableHTMLAttributes<HTMLTableElement>,
  HTMLTableElement
>

export const DMDataTable = React.forwardRef<HTMLTableElement, Props>(
  ({ className, ...props }, forwardedRef) => {
    const localRef = React.useRef<HTMLTableElement>(null)
    const [scrolled, setScrolled] = React.useState(false)
    const [overflowing, setOverflowing] = React.useState(false)

    // Merge local and forwarded refs
    React.useImperativeHandle<HTMLTableElement | null, HTMLTableElement | null>(
      forwardedRef,
      () => localRef.current
    )
    React.useEffect(() => {
      const handleScroll = () => {
        const scrollParent = findScrollParent(localRef.current)

        if (scrollParent) {
          const { scrollLeft, scrollWidth, clientWidth } = scrollParent

          if (scrollLeft > 0.01) {
            setScrolled(true)
          } else {
            setScrolled(false)
          }

          if (Math.abs(scrollLeft + clientWidth - scrollWidth) > 0.01) {
            setOverflowing(true)
          } else {
            setOverflowing(false)
          }
        }
      }

      // Function to find closest parent that is scrollable horizontally
      function findScrollParent(
        element: HTMLElement | null
      ): HTMLElement | null {
        if (element == null) return null

        const style = getComputedStyle(element)

        if (
          style.overflow === "auto" ||
          style.overflow === "scroll" ||
          style.overflowX === "auto" ||
          style.overflowX === "scroll"
        ) {
          return element
        }

        return findScrollParent(element.parentElement)
      }

      const scrollParent = findScrollParent(localRef.current)

      if (scrollParent) {
        scrollParent.addEventListener("scroll", handleScroll)
      }

      const resizeObserver = new ResizeObserver(() => {
        handleScroll()
      })

      if (localRef.current) {
        resizeObserver.observe(localRef.current)
      }

      return () => {
        if (scrollParent) {
          scrollParent.removeEventListener("scroll", handleScroll)
        }

        if (localRef.current) {
          resizeObserver.unobserve(localRef.current)
        }
      }
    }, [])

    return (
      <table
        ref={localRef}
        className={cn(
          "group/table w-full table-auto border-separate",
          scrolled && "scrolled",
          overflowing && "overflowing",
          className
        )}
        {...props}
      />
    )
  }
)

DMDataTable.displayName = "DMDataTable"
