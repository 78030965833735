import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconExternal, IconFigmaColored } from "@supernovaio/icons"
import { DesignComponent } from "@supernovaio/sdk"

import { DMButtonWhite } from "../../DMButtons"
import { DMComponentMiniature } from "../../DMComponents"
import { DMIcon } from "../../DMIcon"
import DMDataTableTd, { TdProps } from "../DMDataTableTd"

import DMDataTableEmptyCell from "./DMDataTableEmptyCell"

export type FigmaComponentCellProps = TdProps & { component?: DesignComponent }

export default function DMDataTableFigmaComponentCell({
  component,
  className,
  ...props
}: FigmaComponentCellProps) {
  if (!component) {
    return <DMDataTableEmptyCell {...props} />
  }

  return (
    <DMDataTableTd className="group/cell px-0" {...props}>
      <div className={cn("relative flex items-center gap-8 px-12", className)}>
        <DMComponentMiniature component={component} height={28} width={40} />
        <DMIcon color="neutral-faded" size="small" svg={<IconFigmaColored />} />
        <div className="text-body flex-1 truncate">{component.name}</div>
        <DMButtonWhite
          isExternalLink
          className="absolute right-12 hidden group-hover/cell:flex"
          href={component.origin?.remoteDesignComponentUrl}
          icon={IconExternal}
          size="small"
        />
      </div>
    </DMDataTableTd>
  )
}
