import * as React from "react"
import { SVGProps } from "react"

export const IconMore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.667 8A.667.667 0 1 0 4 8a.667.667 0 0 0-1.333 0M7.333 8a.667.667 0 1 0 1.334 0 .667.667 0 0 0-1.334 0M12 8a.667.667 0 1 0 1.334 0A.667.667 0 0 0 12 8"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M2.667 8A.667.667 0 1 0 4 8a.667.667 0 0 0-1.333 0M7.333 8a.667.667 0 1 0 1.334 0 .667.667 0 0 0-1.334 0M12 8a.667.667 0 1 0 1.334 0A.667.667 0 0 0 12 8"
    />
  </svg>
)
