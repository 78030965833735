import * as React from "react"
import { SVGProps } from "react"

export const IconFigmaColored = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path fill="#F24E1E" d="M4 4c0-1.133.867-2 2-2h2v4H6c-1.133 0-2-.867-2-2" />
    <path fill="#A259FF" d="M4 8c0-1.133.867-2 2-2h2v4H6c-1.133 0-2-.867-2-2" />
    <path
      fill="#0ACF83"
      d="M6 14c1.133 0 2-.867 2-2v-2H6c-1.133 0-2 .867-2 2s.867 2 2 2"
    />
    <path fill="#1ABCFE" d="M10 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
    <path fill="#FF7262" d="M8 2h2c1.133 0 2 .867 2 2s-.867 2-2 2H8z" />
  </svg>
)
