import React, { memo } from "react"

import { IconFigmaColored, IconTokensStudioColored } from "@supernovaio/icons"
import type { IconType } from "@supernovaio/icons/types"

import { DMIcon } from "../../DMIcon"
import { DMTokenPreviewSwatch } from "../DMTokenPreviewSwatch"

export type DMTokenValueProps = {
  swatch?: React.ReactNode
  value?: React.ReactNode
  tokenSource?: "Figma" | "TokenStudio" | "supernova" | "FigmaVariablesPlugin"
  isEmpty?: boolean
}

export const DMTokenValue = memo(function DMTokenValue(
  props: DMTokenValueProps
) {
  const { swatch, value, tokenSource = "supernova", isEmpty } = props
  let tokenSourceIcon: IconType | null = null

  if (isEmpty) {
    return (
      <div className="flex items-center gap-8">
        <DMTokenPreviewSwatch token={null} />
        <span className="text-neutral-placeholder">Empty</span>
      </div>
    )
  }

  switch (tokenSource) {
    case "Figma":
    case "FigmaVariablesPlugin":
      tokenSourceIcon = IconFigmaColored
      break
    case "TokenStudio":
      tokenSourceIcon = IconTokensStudioColored
      break
    case "supernova":
      tokenSourceIcon = null
      break
  }

  return (
    <div className="flex min-w-0 items-center gap-8">
      {swatch}
      <div className="flex items-center gap-4">
        {tokenSourceIcon && (
          <DMIcon className="shrink-0" svg={tokenSourceIcon} />
        )}
        {value}
      </div>
    </div>
  )
})
