"use client"

import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { Command as CommandPrimitive } from "../../../libraries/cmdk"

interface DMMenuGroupProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>,
    "heading"
  > {
  /** Optional heading to render for this group. */
  title?: string
  /** Enable this in cases where you have non-selectable items (actions) in the group. */
  containsActions?: boolean
}

const DMMenuGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  DMMenuGroupProps
>(({ className, containsActions = false, title, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      "border-neutral-faded -mx-4 mb-4 overflow-hidden border-b px-4 pb-4",
      "last:mb-0 last:border-b-0 last:pb-0",
      "data-[last-visible=true]:border-b-0 data-[last-visible=true]:pb-0 data-[last-visible=true]:mb-0",
      "[&>div[cmdk-group-heading]]:text-body-small [&>div[cmdk-group-heading]]:text-neutral-faded [&>div[cmdk-group-heading]]:mb-4 [&>div[cmdk-group-heading]]:mt-8 [&>div[cmdk-group-heading]]:font-semibold",
      containsActions
        ? "[&>div[cmdk-group-heading]]:pl-8"
        : "[&>div[cmdk-group-heading]]:pl-32",
      "[&>div]:gap-nav-items [&>div]:flex [&>div]:flex-col",
      className
    )}
    heading={title}
    {...props}
  />
))

DMMenuGroup.displayName = CommandPrimitive.Group.displayName

export { DMMenuGroup }
