import * as React from "react"
import { SVGProps } from "react"

export const IconDragHandle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.1 3.48a1.02 1.02 0 1 0 0 2.04 1.02 1.02 0 0 0 0-2.04M9.1 3.48a1.02 1.02 0 1 0 0 2.04 1.02 1.02 0 0 0 0-2.04M6.1 6.98a1.02 1.02 0 1 0 0 2.04 1.02 1.02 0 0 0 0-2.04M9.1 6.98a1.02 1.02 0 1 0 0 2.04 1.02 1.02 0 0 0 0-2.04M6.1 10.48a1.02 1.02 0 1 0 0 2.04 1.02 1.02 0 0 0 0-2.04M9.1 10.48a1.02 1.02 0 1 0 0 2.04 1.02 1.02 0 0 0 0-2.04"
    />
  </svg>
)
