import React from "react"

import { DMTokenValue, DMTokenValueProps } from "../../DMTokenPreview"
import DMDataTableTd, { TdProps } from "../DMDataTableTd"

export type TokenCellProps = DMTokenValueProps &
  Omit<TdProps, keyof DMTokenValueProps>

export default function DMDataTableTokenCell({
  swatch,
  value,
  tokenSource,
  isEmpty,
  ...props
}: TokenCellProps) {
  return (
    <DMDataTableTd {...props}>
      <div className="pl-table-cell w-full overflow-x-hidden">
        <DMTokenValue
          isEmpty={isEmpty}
          swatch={swatch}
          tokenSource={tokenSource}
          value={value}
        />
      </div>
    </DMDataTableTd>
  )
}
