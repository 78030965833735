import * as React from "react"
import { SVGProps } from "react"

export const IconAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M8 6.667h.007m-.007 2v2.666M6.827 2.638l-5.615 9.373A1.326 1.326 0 0 0 2.345 14h11.23a1.326 1.326 0 0 0 1.134-1.989L9.093 2.638a1.326 1.326 0 0 0-2.266 0"
    />
  </svg>
)
