import React, { forwardRef } from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

export type DMDataTableTrProps = React.HTMLAttributes<HTMLTableRowElement> & {
  isClickable?: boolean
  isSelected?: boolean
}

const DMDataTableTr = forwardRef<HTMLTableRowElement, DMDataTableTrProps>(
  ({ className, isSelected, isClickable, onClick, ...props }, ref) => {
    return (
      <tr
        ref={ref}
        className={cn([
          "group/row transition-colors",
          !isSelected &&
            isClickable &&
            "group/clickable-row hover:bg-blur-neutral-faded",
          isClickable && "cursor-pointer",
          isSelected && "selected",
          className,
        ])}
        onClick={isClickable ? onClick : undefined}
        {...props}
      />
    )
  }
)

DMDataTableTr.defaultProps = {
  isClickable: true,
}
DMDataTableTr.displayName = "DMDataTableTr"

export default DMDataTableTr
