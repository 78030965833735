import React from "react"

import { DMBadge } from "../../DMBadge"
import DMDataTableTd, { TdProps } from "../DMDataTableTd"

import { Switch, type SwitchProps as BaseSwitchProps } from "reshaped/bundle"

type SwitchProps = Omit<BaseSwitchProps, "checked" | "defaultChecked" | "style">

type CommonProps = {
  style?: TdProps["style"]
  allowTransition?: boolean
}

type DynamicBooleanCellProps = CommonProps & {
  dynamic: true
  value?: BaseSwitchProps["checked"]
  defaultValue?: BaseSwitchProps["defaultChecked"]
} & SwitchProps

type StaticBooleanCellProps = CommonProps & {
  dynamic?: false
  value: BaseSwitchProps["checked"]
  defaultValue?: never
} & Partial<Record<keyof SwitchProps, never>>

export type BooleanCellProps = DynamicBooleanCellProps | StaticBooleanCellProps

function StaticBooleanCellContent({
  value,
}: {
  value: BaseSwitchProps["defaultChecked"]
}) {
  return value ? (
    <DMBadge color="positive">Yes</DMBadge>
  ) : (
    <DMBadge color="neutral">No</DMBadge>
  )
}

export default function DMDataTableBooleanCell({
  dynamic,
  value,
  defaultValue,
  style,
  allowTransition,
  ...props
}: BooleanCellProps) {
  return (
    <DMDataTableTd
      className="pr-table-cell"
      style={style}
      allowTransition={allowTransition}
    >
      <div className="flex justify-center">
        {dynamic ? (
          <Switch
            {...(props as SwitchProps)}
            checked={value}
            defaultChecked={defaultValue}
          />
        ) : (
          <StaticBooleanCellContent value={value} />
        )}
      </div>
    </DMDataTableTd>
  )
}
