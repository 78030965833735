import React from "react"

import { DMButtonWhite } from "@supernovaio/dm"

import { IconExternal, IconPage } from "@supernovaio/icons"

import { DMIcon } from "../../DMIcon"
import DMDataTableTd, { TdProps } from "../DMDataTableTd"

import DMDataTableEmptyCell from "./DMDataTableEmptyCell"

export type DocumentationLegacyPageCellProps = TdProps & {
  groupText?: string
  pageText?: string
  isRootPage?: boolean
  editorPageUrl?: string
}

export default function DMDataTableDocumentationPageCell({
  groupText,
  pageText,
  isRootPage,
  editorPageUrl,
  className,
  ...props
}: DocumentationLegacyPageCellProps) {
  if (!pageText) {
    return <DMDataTableEmptyCell {...props} />
  }

  return (
    <DMDataTableTd className="group/cell px-0" {...props}>
      <div className="relative flex items-center gap-4 px-12">
        <DMIcon color="neutral-faded" size="small" svg={<IconPage />} />
        <div className="flex min-w-0 flex-1 items-center">
          {groupText && !isRootPage && (
            <>
              <div className="truncate flex-shrink min-w-0 text-body text-neutral-faded whitespace-nowrap overflow-hidden">
                {groupText}
              </div>
              <div className="text-body text-neutral-faded pl-2 pr-4">/</div>
            </>
          )}
          <div className="flex-auto min-w-0 truncate text-body whitespace-nowrap overflow-hidden">
            {pageText}
          </div>
        </div>
        {editorPageUrl && (
          <DMButtonWhite
            className="absolute right-12 hidden group-hover:flex"
            href={editorPageUrl}
            icon={<IconExternal />}
            size="small"
          />
        )}
      </div>
    </DMDataTableTd>
  )
}
