import * as React from "react"
import { SVGProps } from "react"

export const IconTokensStudioColored = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#141618"
      d="M2 8c0-2.828 0-4.243.879-5.121C3.757 2 5.172 2 8 2s4.243 0 5.121.879C14 3.757 14 5.172 14 8s0 4.243-.879 5.121C12.243 14 10.828 14 8 14s-4.243 0-5.121-.879C2 12.243 2 10.828 2 8"
    />
    <path
      fill="#fff"
      d="M5.73 7.7A1.95 1.95 0 1 1 7.7 5.744v.987a.97.97 0 0 1-.97.969h-1M10.439 8A1.8 1.8 0 1 0 8.6 6.188v.924c0 .49.398.888.888.888h.9510000000000001M8.03 10.518v.032A1.65 1.65 0 1 0 9.624 8.9H8.91a.88.88 0 0 0-.881.881v.737M7.128 10.03l.002.07A1.5 1.5 0 1 1 5.7 8.602l.052-.002h.506c.481 0 .872.39.872.872v.506q0 .026-.002.053"
    />
  </svg>
)
