import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { DMBadge, DMBadgeProps } from "../../DMBadge"
import DMDataTableTd, { TdProps } from "../DMDataTableTd"

export type EnumProps = {
  value: string
} & Omit<DMBadgeProps & Omit<TdProps, keyof DMBadgeProps>, "children">

export default function DMDataTableEnumCell({
  value,
  color,
  className,
  size,
  ...props
}: EnumProps) {
  return (
    <DMDataTableTd
      {...props}
      className={cn(
        "overflow-x-hidden",
        value ? "" : "text-neutral-placeholder",
        className
      )}
    >
      <div className="px-table-cell flex justify-center">
        <DMBadge color={color} size={size} truncate>
          {value}
        </DMBadge>
      </div>
    </DMDataTableTd>
  )
}
