import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import DMDataTableTd, { TdProps } from "../DMDataTableTd"

export default function DMDataTableEmptyCell({ className, ...props }: TdProps) {
  return (
    <DMDataTableTd
      className={cn(
        "text-body text-neutral-placeholder px-table-cell",
        className
      )}
      {...props}
    >
      None
    </DMDataTableTd>
  )
}
