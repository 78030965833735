import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconExternal, IconUrl } from "@supernovaio/icons"

import { DMButtonWhite } from "../../DMButtons/DMButtonWhite"
import { DMIcon } from "../../DMIcon"
import DMDataTableTd, { TdProps } from "../DMDataTableTd"

import DMDataTableEmptyCell from "./DMDataTableEmptyCell"

function repairUrl(url: string) {
  const regex = /^(http|https):\/\//

  if (regex.test(url)) {
    return url
  }

  return `https://${url}`
}

export type UrlCellProps = TdProps & {
  url?: string
}

export default function DMDataTableUrlCell({
  url,
  className,
  ...props
}: UrlCellProps) {
  if (!url) {
    return <DMDataTableEmptyCell {...props} />
  }

  return (
    <DMDataTableTd className="group/cell px-0" {...props}>
      <div className={cn("relative flex items-center gap-4 px-12", className)}>
        <DMIcon color="neutral-faded" size="small" svg={<IconUrl />} />
        <div className="text-body flex-1 truncate">{url}</div>
        <DMButtonWhite
          isExternalLink
          className="absolute right-12 hidden group-hover/cell:flex"
          href={repairUrl(url)}
          icon={IconExternal}
          size="small"
        />
      </div>
    </DMDataTableTd>
  )
}
